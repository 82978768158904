@font-face {
    font-family: "General Sans Variable";
    src: url("./regular/GeneralSans-Variable.woff2") format("woff2"),
        url("./regular/GeneralSans-Variable.woff") format("woff"),
        url("./regular/GeneralSans-Variable.ttf") format("truetype");
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "General Sans Variable";
    src: url("./italic/GeneralSans-VariableItalic.woff2") format("woff2"),
        url("./italic/GeneralSans-VariableItalic.woff") format("woff"),
        url("./italic/GeneralSans-VariableItalic.ttf") format("truetype");
    font-weight: 200 700;
    font-display: swap;
    font-style: italic;
}
